<template>
 <div class="home">
        <div class="tabledata" style="height: 100%">
      <el-table :data="tableData" v-loading="loading" style="width: 100%" border>
        <el-table-column prop="taskName" label="任务名称"> 
        </el-table-column>
         <el-table-column prop="taskStartTime" label="任务开始时间"> 
        </el-table-column>
         <el-table-column prop="finishTime" label="任务结束时间"> 
        </el-table-column>

          <el-table-column prop="contacts" label="联系电话"> 
        </el-table-column>
                  <el-table-column prop="statusText" label="状态"> 
        </el-table-column>
        <el-table-column label="操作" align="center" width="380">
        <template slot-scope="scope">
        <template v-if="scope.row.statusText !== '未开始'">
              <span class="handel" @click="getXhmStat(scope.row)">查看详情</span>
              </template>
              </template>
        </el-table-column>
      </el-table>
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
</div>
</div>
</template>
<script>
import { getXhm } from "@/api/xhm";
import router from '@/router';
  export default {
    data() {
      return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false
      };
    },
  mounted() {
    this.search();
  },

    methods: {
      async search(val){
        this.loading = true;
        await getXhm({
          pageNo: this.currentPage, 
          pageSize: this.pageSize
          }).then((res) => {
            this.loading = false;
            if (res.code == 1000) {
      this.tableData = res.data.result;
      this.total = res.data.totalNum;
          }else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }

      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.search(); // 重新搜索以更新分页数据
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(); // 重新搜索以更新分页数据
    },
    getXhmStat(val) {

      let routeData = router.resolve({ name: `灵感小红盟每日数据`,　　　　
        query: {
          taskId: val.taskId,
          taskName:val.taskName,
        } }) 
         window.open(routeData.href, '_blank');
    },
    }
};
</script>

